<template>
  <detail-form :typeList="typeList" :orgList="orgList" @dataUpdate="dataUpdate" :assetsList="assetsList" ref="dForm" :data="chooseData"></detail-form>
</template>

<script>
import detailForm from '../../scrap/apply/form'

export default {
  components: {
    detailForm
  },
  data () {
    return {
      typeList: [{key: 'norm', label: '正常报废'}, {key: 'abnormal', label: '异常报废'}],
      chooseData: null,
      assetsList: []
    }
  },
  created () {
    this.$store.dispatch('loadOrgList')
  },
  computed: {
    orgList () {
      let allData = this.$store.getters.orgList
      let myOrgIds = this.$store.getters.currentOrgId ? [this.$store.getters.currentOrgId] : []
      return allData.filter(v => myOrgIds.length === 0 ||  myOrgIds.includes(v.key))
    }
  },
  methods: {
    dataUpdate () {
      this.$notify({
        title: '系统提示',
        message: '报废申请添加成功',
        type: 'success'
      })
    },
    add (assets) {
      if (!assets || !assets.id) {
        this.$notify({
          title: '系统提示',
          message: '数据异常',
          type: 'error'
        })
        return
      }
      this.assetsList = [assets]
      this.chooseData = {
        assetsId: assets.id
      }
      this.$refs.dForm.modal = true
    }
  }
}
</script>

<style scoped lang="less">
.apply-page {
  background: #fff;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  margin: 10px;
  .title-sort {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .p-d-c {
    width: calc(100% - 40px);
    height: calc(100% - 88px);
  }
  .p-d-s {
    width: 100%;
    height: calc(100% - 48px);
  }
  .p-d-s-n {
    width: 100%;
    height: calc(100% - 48px);
    font-size: 20px;
    color: #515a6e;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      padding: 20px;
    }
  }
  .left {
    height: calc(100% - 20px);
    overflow-y: auto;
    width: 300px;
    border-right: 1px solid #e8eaec;;
    padding-right: 20px;
  }
  .right {
    height: calc(100% - 20px);
    flex: 1;
    overflow-y: auto;
    border-top: 1px solid #e8eaec;;
    border-right: 1px solid #e8eaec;;
    border-bottom: 1px solid #e8eaec;;
    .right-btns {
      padding:10px 20px;
      display: flex;
      justify-content: space-around;
    }
  }
  .sign-item {
    margin-bottom: 10px;
  }
}
</style>